import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home, Preview } from "Pages";
import { AppContext } from "../Store/AppContext";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import "./App.scss";

const App = () => {
  const history = createBrowserHistory();
  ReactGA.pageview(history.location.pathname + history.location.search)

  const context = useContext(AppContext);
  return (
    <Container
      fluid
      className="App"
      style={{ backgroundColor: context.state.branding.backgroundColor }}
    >
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/workout/:trainingCode" component={Home} />
          <Route
            exact={true}
            path="/preview/workout/:trainingCode"
            component={Preview}
          />
        </Switch>
      </BrowserRouter>
    </Container>
  );
};

export { App };
