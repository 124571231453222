import { VideoBody, VideoHeader, VideoBodyOverlay } from "Components";
import React from "react";

const RoutinePlayer = () => {
  return (
    <>
      <VideoHeader />
      <VideoBodyOverlay />
      <VideoBody />
    </>
  );
};

export default RoutinePlayer;
