import React, { useContext } from "react";
import CookieConsent from "react-cookie-consent";
import { AppContext } from "Store/AppContext";
import ReactGA from 'react-ga';

const containerStyles = {
  "align-items": "baseline",
  background: "rgb(53, 53, 53) none repeat scroll 0% 0%",
  color: "white",
  display: "flex",
  "flex-wrap": "wrap",
  "justify-content": "space-between",
  left: "50%",
  top: "50%",
  position: "sticky",
  "z-index": "999",
};

const contentStyles = {
  flex: "1 0 300px",
  margin: "15px",
};

const Consent = () => {
  const context = useContext(AppContext);
  const dispatch = context.dispatch;
  return (
    <CookieConsent
      containerStyles={containerStyles}
      contentStyle={contentStyles}
      contentClasses="row no-gutters consent_content"
      buttonClasses="row no-gutters btn btn-warning"
      onAccept={() => {
        
        ReactGA.event({
          category: 'Customer',
          action: 'Accept Consent'
        });

        dispatch({
          type: "ACCEPT_CONSENT",
          payload: {
            isCookieAccepted: true,
          },
        });
      }}
    >
      <p>
        I consent to that Aktiv TV will send me marketing materials. I also
        understand there are risks connected with my use of online content,
        including fitness videos, I voluntarily elect to engage in such
        activities knowing that it may cause hazardous to my person and
        property, and I waive any and all claims relating to such activities.
      </p>
    </CookieConsent>
  );
};

export { Consent };
