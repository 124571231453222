import { getCompanyBranding, getWorkout } from "Api/Index";

const executeEffect = async (props) => {
  const companyId = props.companyId;
  const trainingCode = props.trainingCode;
  let branding;
  let workout;
  try {
    branding = await handlingBranding(companyId, null);
    workout = await handlingWorkout(companyId, trainingCode);
    return await handlingEffect(branding, workout);
  } catch (error) {
    return await handlingEffect(branding, workout);
  }
};

const handlingBranding = async (companyId, themeId) => {
  try {
    const branding = await getCompanyBranding(companyId.cid, themeId);
    return branding.status ? {} : branding;
  } catch (error) {
    const msg = "Error - There was an error consuming the branding service => ";
    console.log(msg, error);
    return { errorMsg: "" };
  }
};

const handlingWorkout = async (companyId, trainingCode) => {
  try {
    const workout = await getWorkout(companyId, trainingCode);
    return workout.status ? {} : workout;
  } catch (error) {
    const msg = "Error - There was an error consuming the workout service => ";
    console.log(msg, error);
    return { errorMsg: "" };
  }
};

const handlingEffect = (branding, workout) => {
  const hasValidData = {
    branding: branding.errorMsg === undefined,
    workout: workout.errorMsg === undefined,
  };
  let result = {};

  const checkError = (result, branding, hasValidData) => {
    if (!hasValidData.workout || !hasValidData.branding) {
      const msg = !hasValidData.workout ? workout.errorMsg : branding.errorMsg;
      result["error"] = msg;
    }
    return result;
  };

  if (hasValidData.branding || hasValidData.Workout) {
    const action = {
      type: getActionType(hasValidData.branding, hasValidData.workout),
      payload: getPayload(branding, workout),
    };

    result = checkError(result, branding, hasValidData);
    result["action"] = action;
    return result;
  } else {
    result = checkError(result, branding, hasValidData);
  }
  return result;
};

const getActionType = (hasValidBranding, hasValidWorkout) => {
  let actionType = "SET_HOME";
  actionType = hasValidWorkout ? actionType : "SET_BRANDING";
  actionType = hasValidBranding ? actionType : "";
  return actionType;
};

const getPayload = (branding, workout) => {
  let payload = {};
  if (Object.entries(branding).length > 0) {
    payload["branding"] = branding;
  }

  if (Object.entries(workout).length > 0) {
    payload["workout"] = workout;
  }
  return payload;
};

export { executeEffect };
