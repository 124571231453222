import "./Home.scss";
import { Consent } from "Components/Consent";
import { Banner } from "Components/Banner";
import { ErrorMessage } from "Components/ErrorMessage";
import RoutinePlayer from "Components/Video/RoutinePlayer";
import * as HomeUseCase from "Domain/HomeUseCase";
import Cookies from "js-cookie";
import * as queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { AppContext } from "Store/AppContext";

const Home = (props) => {
  const context = useContext(AppContext);

  const searchQuery = props.location.search;
  const trainingCode = props.match.params;
  const dispatch = context.dispatch;
  const homeClass = Cookies.get("CookieConsent") ? "" : "home-overlay";
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParam = queryString.parse(searchQuery);
    if (queryParam.cid === undefined || queryParam.cid === "") {
      const msg =
        "The workout link seems to be invalid or have expired. Please contact your trainer.";
      setError(msg);
      setLoading(false);
    } else {
      const props = {
        companyId: queryParam.cid,
        trainingCode: trainingCode.trainingCode,
      };

      const effectUseCase = async (props) => {
        const response = await HomeUseCase.executeEffect(props);
        return await response;
      };

      effectUseCase(props).then((response) => {
        if (!response.action.payload.workout) {
          setError('The workout link seems to be invalid or have expired. Please contact your trainer.');
          setLoading(false);
        }
        if (response.errorMsg) {
          setError(response.errorMsg);
        } else {
          dispatch(response.action);
          setLoading(false);
        }
      });
    }
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <ClipLoader size={150} color={"#ffffff"} loading={loading} />
      </div>
    );
  }

  if (error !== "") {
    return <ErrorMessage text={error} />;
  }

  return (
    <>
      {/* <Banner /> */}
      <RoutinePlayer />
      <div className={homeClass} />
      <Consent />
    </>
  );
};

export { Home };
