import { VideoColumn } from "Components/Video/Body/VideoColumn/VideoColumn";
import React from "react";
import { Row } from "react-bootstrap";

const VideoRow = (props) => {
  const rows = Object.values(props.rows);
  const columns = props.columns;
  const chunk = props.chunk;
  return (
    <>
      {rows.map((index) => {
        return (
          <Row key={"row_" + index}>
            <VideoColumn
              key={"vc_" + index}
              values={columns[index]}
              chunk={chunk}
            />
          </Row>
        );
      })}
    </>
  );
};

export { VideoRow };
