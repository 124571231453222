import { VideoPlayer } from 'Components/Video/Body/VideoPlayer';
import React, { memo, useContext, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { AppContext } from 'Store/AppContext';
import './VideoColumn.scss';

export const VideoColumn = (props) => {
  const context = useContext(AppContext);
  const isPlaying = context.state.isPlayingVideos;
  const values = props.values;
  if (props.chunk > values.length) values.push('');
  const width = 12 / values.length;
  const videos = values.map((data, index) => (
    <Col key={'col_' + index} xs={12} lg={width}>
      <div className="vc_container">
        <Parameters amount={data.amount} unit={data.unit} />
        <Overlay name={data.name} />
        <VideoPlayer key={'v_' + index} url={data.url} isPlaying={isPlaying} />
      </div>
    </Col>
  ));
  return videos;
};

const Parameters = memo(({ amount, unit }) => {
  return (
    <>
      <div className="vc_params_container">
        <div className="vc_params">
          <div className="vc_params_amount">{amount}</div>
          <div className="vc_params_unit">{unit}</div>
        </div>
      </div>
    </>
  );
});

const Overlay = memo(({ name }) => {
  return (
    <div className="vc_overlay">
      <div className="vc_gradient">
        <div className="vc_title">{name}</div>
      </div>
    </div>
  );
});
