import React, { memo, useCallback, useContext, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AppContext } from "Store/AppContext";
import "./VideoBodyOverlay.scss";
import {
  getFrameDescription,
  getNextFrameByType,
} from 'Domain/Service/TimerService';

export const VideoBodyOverlay = () => {
  const context = useContext(AppContext);
  const isActive = context.state.pauseTimer.isActive;
  const { branding } = context.state;
  const dispatch = useRef(context.dispatch);
  const timer = context.state.pauseTimer;
  const description = timer.type ? timer.type.toUpperCase() : '';

  const renderTime = (value, description) => {
    if (value === 0) return "00:00";
    const mins = Math.floor((value / 60) % 60);
    const secs = Math.floor(value % 60);
    const clock = `${mins < 10 ? "0" + mins : mins}:
    ${secs < 10 ? "0" + secs : secs}`;

    return (
      <div className="video-body-timer-container">
        <div className="video-body-timer-value">{clock}</div>
        <div className="video-body-timer-title">{description}</div>
      </div>
    );
  };  

  const finishTimer = useCallback(() => {
    if (isActive) {
      dispatch.current({
        type: "FINISH_TIMER",
        payload: {
          origin: "pause",
        },
      });
    }
  }, [isActive]);

  const getTimerDescription = () => {
    if (timer.type === "prep") { return null; }
    return (
      <div className="video-body-description">{timer.description}</div>
    );
  };

  const getTimerWithMessage = () => {
    return (
      <div className="video-body-flex-container">
        <div className="video-body-flex-item">
          { getTimerDescription() }
        </div>
        <div className="video-body-flex-item">
          <Children
            renderTime={renderTime}
            description={description}
            completeEvent={finishTimer}
            keyAttribute={getKeyAttribute(timer)}
            duration={timer.duration}
            barColor={branding.primaryColor}
          />
        </div>
        <div className="video-body-flex-item" />
      </div>
    );
  };

  const getBottomMessage = () => {
    let title = '';
    let subTitle = '';

    const { currentPage, currentVideo } = context.state;
    const { exercisesByPage } = context.state.workout

    switch(timer.type) {
      case 'rest':
        const exercise = exercisesByPage[currentPage].exercises[currentVideo+1];
        if (exercise !== undefined) {
          const name = exercise.name;
          title = 'Prepare for';
          subTitle = `${name}`;  
        }
        break;
      case 'countdown':
        const exerciseCountDown = exercisesByPage[currentPage].exercises[currentVideo];
        if (exerciseCountDown !== undefined) {
          const name = exerciseCountDown.name;
          title = 'Finishing';
          subTitle = `${name}`;
        }
        break;
      case 'break':
        title = 'Next segment';
        const nextTrainFrame = getNextFrameByType(context.state, 'train');
        subTitle = getFrameDescription(nextTrainFrame);
        break;  
    }
    
    return (
      <div className="video-body-bottom-message">
        <div className="video-body-flex-bottom-message">
          <div className="video-body-bottom-message-title">{title}</div>
          <div className="video-body-bottom-message-subtitle">{subTitle}</div>
        </div>
      </div>
    );
  };

  const Children = memo(
    ({ renderTime, description, completeEvent, keyAttribute, duration, barColor }) => {
      return (
        <CountdownCircleTimer
          key={keyAttribute}
          renderTime={(value) => renderTime(value, description)}
          isPlaying={true}
          size={300}
          durationSeconds={duration}
          colors={[[barColor, 0.33]]}
          onComplete={completeEvent}
          strokeWidth={2}
        />
      );
    }
  );
  
  const getClassName = (isActive) => {
    return isActive ? "video-body-layout" : "video-body-layout d-none";
  };
  
  const getKeyAttribute = (timer) => {
    return timer.type === undefined ? "inactive_pause_timer" : timer.type;
  };

  return (
    <div className={getClassName(timer.isActive)}>
      { getBottomMessage() }
      { getTimerWithMessage() }
    </div>
  );
};