import React, { useContext } from "react";
import { AppContext } from "../../Store/AppContext";
import "./ErrorMessage.scss";

export const ErrorMessage = (props) => {
    const { text } = props;
    const context = useContext(AppContext);
    const { logo_url } = context.state.branding;

    return (
        <div className="error-container">
            <div className="error-image">
                <img className="header-image" src={ logo_url }  alt="Logo" />
            </div>
            <div className="error-message">
                { text }
            </div>
        </div>
    );
};
