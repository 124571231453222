import { ACTIVITY_TYPES, PAUSE_TYPES } from 'Constants/TimerConstants';

export const getActivityTimer = (state) => {
  return handler(state, ACTIVITY_TYPES);
};

export const getPauseTimer = (state) => {
  return handler(state, PAUSE_TYPES);
};

export const getCurrentFrame = (state) => {
  const index = getCurrentFrameIndex(state);
  const timeframes = getTimeframes(state);
  if (index < 0 || timeframes === undefined) return undefined;
  return timeframes[index];
};

export const getCurrentFrameDescription = (state) => {
  const frame = getCurrentFrame(state);
  return getFrameDescription(frame);
};

export const getFrameDescription = (frame) => {
  const description = frame !== undefined ? frame.description : '';
  return description !== undefined ? description : '';
}

export const getCurrentFrameIndex = (state) => {
  return state === undefined ? -1 : state.currentFrame;
};

export const getCurrentRound = (state) => {
  return state === undefined ? -1 : state.currentRound;
};

export const getCurrentVideoIndex = (state) => {
  return state === undefined ? -1 : state.currentVideo;
};

export const getNextFrameByType = (state, type) => {
  if (isLastFrame(state)) {
    return {};
  } else {
    const timeframes = getTimeframes(state);
    const currentIndex = getNextFrameIndex(state);
    const nextByType = timeframes.find(function(item,index, self) {
      return ((self.indexOf(item) > currentIndex) && (item.type === type));
    });
    return timeframes[nextByType];
  }
}

export const getNextFrame = (state) => {
  const timeframes = getTimeframes(state);
  const current = getNextFrameIndex(state);
  const timer = { isActive: false };
  if (timeframes === undefined) return timer;
  if (current < 0) return timer;
  return timeframes[current];
};

export const getNextFrameIndex = (state) => {
  if (isLastFrame(state)) {
    if (isLastRound(state)) {
      return -1;
    } else {
      return 0;
    }
  } else {
    return getCurrentFrameIndex(state) + 1;
  }
};

export const getFramesQty = (state) => {
  const timeframes = getTimeframes(state);
  return timeframes === undefined ? 0 : timeframes.length;
};

export const getNextPage = (state) => {
  if (isLastPage(state)) {
    return isLastRound(state) ? state.currentPage : 0;
  } else {
    return getCurrentPage(state) + 1;
  }
};

export const getVideoIndex = (state) => {
  const currentPage = getCurrentPage(state);
  if (currentPage < 0) return currentPage;

  const frame = getCurrentFrame(state);
  if (frame.indexPage && frame.indexPage === currentPage) {
    const videosQty = getPageVideosQty(state, currentPage);
    const currentVideo = getCurrentVideoIndex(state);
    return currentVideo + 1 >= videosQty ? 0 : currentVideo + 1;
  } else {
    return 0;
  }
};

export const getCurrentPage = (state) => {
  return state === undefined ? -1 : state.currentPage;
};

export const getPagesQty = (state) => {
  const workout = getWorkout(state);
  if (workout === undefined) return 0;

  const exercisesByPage = workout.exercisesByPage;
  return exercisesByPage === undefined ? 0 : exercisesByPage.length;
};

export const getPageVideosQty = (state, currentPage) => {
  if (state === undefined) return -1;

  if (currentPage < 0) return currentPage;

  const timeframes = getTimeframes(state);
  if (timeframes === undefined) return -1;

  const exercisesByPage = timeframes[currentPage];
  if (exercisesByPage === undefined) return -1;
  return exercisesByPage.length;
};

export const getRound = (state) => {
  if (state === undefined) return -1;
  if (isLastFrame(state)) {
    const round = getCurrentRound(state);
    return isLastRound(state) ? round : round + 1;
  } else {
    return getCurrentRound(state);
  }
};

export const getRoundsQty = (state) => {
  const workout = getWorkout(state);
  return workout === undefined ? -1 : workout.rounds;
};

export const getTimeframes = (state) => {
  const workout = getWorkout(state);
  return workout === undefined ? undefined : workout.timeframes;
};

export const getTimeframesQty = (state) => {
  const timeframes = getTimeframes(state);
  return timeframes === undefined ? 0 : timeframes.length;
};

export const getWorkout = (state) => {
  return state === undefined ? undefined : state.workout;
};

export const handler = (state, types) => {
  const timer = { isActive: false, duration: 0 };
  const index = getCurrentFrameIndex(state);
  if (index < 0) return timer;

  const timeframes = state.workout.timeframes;
  const frame = timeframes[index];
  if (!frame) return { isActive: false, duration: 0 };
  const isActive = types.some((element) => element === frame.type);
  if (isActive) {
    const timer = {};
    timer['isActive'] = isActive;
    timer['description'] = frame.description;
    timer['duration'] = frame.duration;
    timer['type'] = frame.type;
    return timer;
  } else {
    return timer;
  }
};

export const isTrainFrame = (frame) => {
  return frame !== undefined && frame.type === 'train';
};

export const isInfiniteRounds = (state) => {
  const rounds = getRoundsQty(state);
  return rounds === 0;
};

export const isInSamePage = (frame, state) => {
  return frame.indexPage > state.currentPage;
};

export const isLastFrame = (state) => {
  if (state === undefined) return false;

  const framesQty = getTimeframesQty(state);
  return state.currentFrame + 1 === framesQty;
};

export const isLastPage = (state) => {
  if (state === undefined) return false;

  return state.currentPage + 1 === getPagesQty(state);
};

export const isLastRound = (state) => {
  if (state === undefined) return false;

  if (isInfiniteRounds(state)) return false;

  const currentRound = state.currentRound;
  return currentRound + 1 === getRoundsQty(state);
};
