import { VideoRow } from 'Components/Video/Body/VideoRow';
import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { AppContext } from 'Store/AppContext';
import { getWorkoutPage } from 'Domain/Service/VideoService';

const organizeData = (input) => {
  const length = input.length;
  const chunk = length === 4 ? 2 : length < 4 ? 1 : 3;
  const columns = chunk === 1 ? [input] : sliceData(input, chunk);
  return { rows: length <= 3 ? [0] : [0, 1], columns: columns, chunk: chunk };
};

const sliceData = (input, chunk) => {
  const results = [];
  while (input.length) {
    results.push(input.splice(0, chunk));
  }
  return results;
};

const VideoBody = (props) => {
  const context = useContext(AppContext);
  const page = getWorkoutPage(context.state);
  const currentVideo = context.state.currentVideo;
  let exercises = [];

  if (page) {
    if (page.isPlayingAll) {
      exercises = page.exercises;
    } else {
      exercises = [page.exercises[currentVideo]];
    }
  }
  const input = organizeData(Object.values(exercises));
  return (
    <Container fluid>
      <VideoRow rows={input.rows} columns={input.columns} chunk={input.chunk} />
    </Container>
  );
};

export { VideoBody };
