import { getCompanyTraining, getCompanyTheme } from './Consumer';

function getExerciseNameAndUrl(exercisesList, xcode) {
  let exerciseData = { name: '', videoUrl: '' };
  let exercise = exercisesList.find(
    (exerciseItem) => exerciseItem.ucode === xcode
  );
  if (exercise) {
    exerciseData.name = exercise.name;
    exerciseData.videoUrl = exercise.videoUrl;
  }
  return exerciseData;
}

function getExerciseUnitAndAmount(xparams, xcode) {
  let exerciseData = { unit: '', amount: '' };
  let exercise = xparams.find((exerciseItem) => exerciseItem.xcode === xcode);
  if (exercise) {
    exerciseData.unit = exercise.unit;
    exerciseData.amount = exercise.amount;
  }
  return exerciseData;
}

function getPageExercises(xcodes, xparams, exercises) {
  let newExercises = xcodes.map(function (xcodeItem) {
    let exerciseNameData = getExerciseNameAndUrl(exercises, xcodeItem);
    let exerciseParamsData = getExerciseUnitAndAmount(xparams, xcodeItem);
    let exerciseData = {
      name: exerciseNameData.name,
      url: exerciseNameData.videoUrl,
      unit: exerciseParamsData.unit,
      amount: exerciseParamsData.amount,
    };
    return exerciseData;
  });
  return newExercises;
}

async function loadTimeframes(roundTimeline, exercises) {
  let indexPage = 0;
  let prevPageName = '';
  let timeframes = [];
  let exercisesByPage = [];

  roundTimeline.timeframes.forEach((timeframeItem) => {
    let newTimeFrame = {
      type: timeframeItem.type,
      duration: timeframeItem.duration,
      description: timeframeItem.description,
    };

    if (timeframeItem.type === 'train') {
      newTimeFrame.isPlayingAll =
        timeframeItem.play.length === 0 ? true : false;
      newTimeFrame.indexPage = indexPage;
      newTimeFrame.indexVideo =
        timeframeItem.play.length === 0 ? 0 : timeframeItem.play[0];
      const pageChange =
        newTimeFrame.isPlayingAll ||
        timeframeItem.play[0] === timeframeItem.xcodes.length - 1;
      if (pageChange) {
        exercisesByPage[indexPage] = {
          isPlayingAll: newTimeFrame.isPlayingAll,
          exercises: getPageExercises(
            timeframeItem.xcodes,
            timeframeItem.xparams,
            exercises
          ),
        };
        indexPage = indexPage + 1;
      }
      prevPageName = timeframeItem.description;
    }
    timeframes.push(newTimeFrame);
  });

  return { timeframes: timeframes, exercisesByPage: exercisesByPage };
}

export async function getWorkout(companyCode, trainingCode) {
  try {
    let workoutData = {};
    const response = await getCompanyTraining(companyCode, trainingCode);
    if (response.data) {
      let procesedTmeframes = await loadTimeframes(
        response.data.roundTimeline,
        response.data.exercises
      );
      workoutData = {
        name: response.data.name,
        description: response.data.description,
        notes: response.data.notes,
        equipments: response.data.equipments.map((element) => element.name),
        rounds: response.data.rounds,
        timeframes: procesedTmeframes.timeframes,
        exercisesByPage: procesedTmeframes.exercisesByPage,
      };
    }
    return workoutData;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getCompanyBranding(companyCode, themeCode) {
  let brandingData = {
    logo_url: 'https://aktiv-dev.s3-us-west-1.amazonaws.com/demo/logo.png',
    backgroundColor: '#000',
    primaryColor: '#fff',
    accentColor: '#5ca459',
  };

  if (!themeCode) {
    companyCode = process.env.REACT_APP_DEFAULT_COMPANY_CODE;
    themeCode = process.env.REACT_APP_DEFAULT_THEME_CODE;
  }

  try {
    const response = await getCompanyTheme(companyCode, themeCode);
    if (response.data) {
      brandingData = {
        logo_url: response.data.logoUrl,
        backgroundColor: response.data.backgroundColor,
        primaryColor: response.data.primaryColor,
        accentColor: response.data.accentColor,
      };
    }
  } catch (error) {
    return error;
  }
  return brandingData;
}
